import React, { FC } from 'react';

import ImageBackground from '../components/core/image-background';

import MetaModule from '../components/core/meta-module';
import EditorialNewsletterModule from '../components/editorial/editorial-newsletter-module';

const TemplateNewsletterSignup: FC = () => {
  return (
    <div className="x y">
      <ImageBackground
        source="/peaceful-astronaut.jpg"
        className="z0 mt3"
        position="right top"
      />
      <MetaModule title="Newsletter" path="/newsletter" />
      <div className="x y show--s">
        <div className="x y f aic jcc">
          <div className="z1 bcb cw p1 pb15">
            <EditorialNewsletterModule
              darkBackground={true}
              backgroundColor="black"
              data={{
                headline: 'Subscribe',
                tagline: 'Space stories in your inbox, four times a month',
                cta: 'Sign Up',
                successMessage:
                  'We got you. More supercluster headed your way.',
                placeholder: 'YOURNAME@EMAIL.COM'
              }}
            />
          </div>
        </div>
      </div>
      <div className="x y hide--s">
        <div className="x y f aie jcc">
          <div className="z1 x bcb cw">
            <EditorialNewsletterModule
              darkBackground={true}
              backgroundColor="black"
              data={{
                headline: 'Subscribe',
                tagline: 'Space stories in your inbox, four times a month',
                cta: 'Sign Up',
                successMessage:
                  'We got you. More supercluster headed your way.',
                placeholder: 'YOURNAME@EMAIL.COM'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateNewsletterSignup;
